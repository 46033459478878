import { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import Preloader from "../components/Preloader";

const Home = () => {
  const [messages, setMessages] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageToOpen, setMessageToOpen] = useState<any>({});

  useEffect(() => {
    const getMessages = async () => {
      const { data } = await axios.get(
        "https://app.importwithapex.com/api/message"
      );
      setMessages(data);
    };

    getMessages();
    setTimeout(() => {
      setPageLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {pageLoading ? (
        <Preloader />
      ) : (
        <div className="nk-body bg-lighter npc-general has-sidebar ui-bordered ">
          <div className="nk-app-root">
            <div className="nk-main ">
              <Sidebar />
              <div className="nk-wrap ">
                <Header />
                <div className="nk-content ">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                          <div className="nk-block-between">
                            <div className="nk-block-head-content">
                              <h3 className="nk-block-title page-title">
                                Dashboard
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="nk-block">
                          <div className="row g-gs">
                            <div className="col-xxl-3 col-sm-6">
                              <div className="card">
                                <div className="nk-ecwg nk-ecwg6">
                                  <div className="card-inner">
                                    <div className="card-title-group">
                                      <div className="card-title">
                                        <h6 className="title">
                                          Total Messages
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="data">
                                      <div className="data-group">
                                        <div className="amount">
                                          {messages?.length?.toLocaleString()}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xxl-8">
                              <div className="card card-full">
                                <div className="card-inner">
                                  <div className="card-title-group">
                                    <div className="card-title">
                                      <h6 className="title">Recent Messages</h6>
                                    </div>
                                  </div>
                                </div>

                                {messages?.length > 0 && (
                                  <div className="nk-tb-list is-separate is-medium mb-3">
                                    <div className="nk-tb-item nk-tb-head">
                                      <div className="nk-tb-col">
                                        <span className="sub-text">Name</span>
                                      </div>
                                      <div className="nk-tb-col tb-col-md">
                                        <span className="sub-text">Email</span>
                                      </div>
                                      <div className="nk-tb-col tb-col-lg">
                                        <span className="sub-text">
                                          Message
                                        </span>
                                      </div>
                                      <div className="nk-tb-col tb-col-lg">
                                        <span className="sub-text">Date</span>
                                      </div>

                                      <div className="nk-tb-col nk-tb-col-tools"></div>
                                    </div>
                                    {messages?.map((message: any) => (
                                      <div
                                        className="nk-tb-item"
                                        key={message._id}
                                      >
                                        <div className="nk-tb-col">
                                          <div className="user-card">
                                            {/* <div className="user-avatar bg-primary">
                                      <span>AB</span>
                                    </div> */}
                                            <div className="user-info">
                                              <span className="tb-lead">
                                                {message.name}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="nk-tb-col tb-col-md">
                                          <span>{message.email}</span>
                                        </div>
                                        <div className="nk-tb-col tb-col-lg">
                                          <span>
                                            {message?.text?.substring(0, 35)}
                                            {message?.text?.length >= 35 &&
                                              "..."}
                                          </span>
                                        </div>
                                        <div className="nk-tb-col tb-col-lg">
                                          <span>
                                            {new Date(
                                              message.createdAt
                                            ).toLocaleDateString()}
                                          </span>
                                        </div>

                                        <div className="nk-tb-col nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            <li>
                                              <div className="drodown">
                                                <a
                                                  href="#/"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  <em className="icon ni ni-more-h" />
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                  <ul className="link-list-opt no-bdr">
                                                    <li>
                                                      <a
                                                        href="#/"
                                                        onClick={() => {
                                                          setMessageModalOpen(
                                                            true
                                                          );
                                                          setMessageToOpen(
                                                            message
                                                          );
                                                        }}
                                                      >
                                                        <em className="icon ni ni-eye" />
                                                        <span>
                                                          View Details
                                                        </span>
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}

      {messageModalOpen && (
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            id="modalDefault"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <div>
                    <h5 className="modal-title">{messageToOpen?.name}</h5>
                    <p className="modal-title">{messageToOpen?.email}</p>
                    <p className="modal-title">{messageToOpen?.phone}</p>
                  </div>
                  <a
                    href="#/"
                    onClick={() => setMessageModalOpen(false)}
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross" />
                  </a>
                </div>
                <div className="modal-body">
                  <p>{messageToOpen?.text}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={() => setMessageModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

export default Home;
